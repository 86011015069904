import React, { Fragment, useState } from 'react';

import { filterOldEvents } from '../utils/dateFormatting';
import { EventRow, Hero, MetaData, TitleSection } from '../components';

const removeOldEvents = (events) =>
	events ? events.filter(filterOldEvents) : [];

const Events = ({ pageContext: { title, slug, acf, options, events } }) => {
	const [filteredEvents] = useState(removeOldEvents(events));

	return (
		<Fragment>
			<MetaData
				title={acf.meta_title || title}
				desciption={acf.meta_description}
			/>

			{acf.events_header_images && (
				<Hero
					images={acf.events_header_images}
					title={acf.events_header_title}
					logo={acf.events_header_logo && acf.events_header_logo.source_url}
					link={acf.events_header_link}
					linkText={acf.events_header_link_text}
					linkPopup={acf.events_header_link_popup}
				/>
			)}

			<TitleSection
				content={
					<div className="w-container events">
						<div className="w-row">
							<div className="w-col w-col-5 events__events">
								{filteredEvents.map((e) => (
									<EventRow
										title={e.title}
										slug={e.slug}
										key={e.slug}
										{...e.acf}
									/>
								))}
							</div>
						</div>
					</div>
				}
			/>
		</Fragment>
	);
};

export default Events;
